import { useAuth } from "@/auth/hooks/useAuth";
import ReactBreadcrumb from "@/components/breadcrumb/Breadcrumb";
import HeaderForm from "@/components/layout/HeaderForm";
import ProgressBar from "@/components/progress/ProgressBar";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import {
  convertStringToNumber,
  reverseDataProcessingProject,
} from "@/libs/utils";
import StepRenderer from "@/programs/components/steps/StepRenderer";
import Treeview from "@/programs/components/Treeview";
import { TypeProjectProvider, useTypeProjectContext } from "@/programs/context/typeProgramContext";
import useProgram from "@/programs/hooks/useProgram";
import useTypeProject from "@/programs/hooks/useTypeProject";
import { fetchProgram } from "@/programs/services/programService";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import slugify from "slugify";

const Program: FC = () => {
  const navigate = useNavigate();

  const code = localStorage.getItem("code")

  if(code !== import.meta.env.VITE_API_CODE) {
    navigate(`/`);
  }

  const { id } = useParams<{ id: string }>();
  const idParams = convertStringToNumber(id);
  const { programData, currentId, updateProgramData } =
    useProgram();
  const [loadData, setLoadData] = useState<boolean>(true);
  const { indexTypeProjet } = useTypeProject(
    idParams as number,
    JSON.parse(localStorage.getItem("typesProject") || "[]")
  );
  const program = programData[idParams as number];
  const DefaultbreadcrumbItems = [
    {
      href: "/programs",
      name: "Mes programmes"
    },
    // Vérification si programName n'est pas undefined avant de l'ajouter
    ...(programData[idParams]?.programName ? [{
      name: programData[idParams].programName
    }] : [])
  ]
  const [breadcrumbItems, setBreadcrumbItems] = useState<{name:string;href?:string;}[]>(DefaultbreadcrumbItems)
  
  useEffect(() => {
    handleChangeStep(1);
    if (currentId === null) {
      return;
    }

    fetchProgram(idParams as number).then(async (data) => {
      if (data && data.projects !== undefined) {
        const slugs = [];
        let projects = [];
        for (let i = 0; i < data.projects.length; i++) {
          const project = data.projects[i];
          const slug = slugify(project.type.name, {
            lower: true,
            remove: /[*+~.()'"!:@]/g,
          });
          slugs.push(slug);
          projects = [...projects, reverseDataProcessingProject(project)];
        }

        updateProgramData("typesProject", slugs);

        updateProgramData("projects", projects.length ? [...projects] : [{}]);
        
        updateProgramData("programName", data.name);
        updateProgramData("programDescription", data.description);
        updateProgramData("programId", data.id);
      }
    });
  }, [currentId]);

  useEffect(() => {
    if (
      programData[idParams] !== undefined &&
      programData[idParams].projects.length
    ) {
      setBreadcrumbItems([
        {
          href: "/programs",
          name: "Mes programmes"
        },
        // Vérification si programName n'est pas undefined avant de l'ajouter
        ...(programData[idParams]?.programName ? [{
          name: programData[idParams].programName
        }] : [])
      ])
      setLoadData(false);
    } else {
      setLoadData(true);
    }
  }, [programData]);

  /**
   * Changelog [FIX] (Hoby) On rédirige l'utilisateur s'il ne met pas d'id dans l'url ou l'id n'est pas nombre
   */
  useEffect(() => {
    if (!idParams) {
      navigate("/programs");
    }
  }, [idParams, navigate]);

  useEffect(() => {
    if (
      programData[idParams as number] !== undefined &&
      programData[idParams as number]?.projects?.length
    ) {
      setLoadData(false);
    } else {
      window.scrollTo(0, 0);
      setLoadData(true);
    }
  }, [programData]);

  const [currentStep, setCurrentStep] = useState<number>(() => {
    const stepLocalStorage = localStorage.getItem("step");
    return stepLocalStorage ? JSON.parse(stepLocalStorage) : 1;
  });

  useEffect(() => {
    if(currentStep > 1){
      setBreadcrumbItems([
        {
          href: "/programs",
          name: "Mes programmes"
        },
        {
          name: programData[idParams]?.programName || '',
          href: `/program/${idParams}`
        },
        {
          name: programData[idParams]?.projects[indexTypeProjet]?.name || ''
        }
      ])
    }
  }, [currentStep, programData])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const handleChangeStep = (step: number) => {
    setCurrentStep(step);
    localStorage.setItem("step", JSON.stringify(step));
  };

  const { isAuthenticated, loading } = useAuth();
  //Si l'utilisation n'est déjà connecté, on le redirige vers la page login
  if (!isAuthenticated && !loading) {
    navigate(`/login`);
  }

  return (
    <TypeProjectProvider>
      <div className="mb-0 container">
        <ReactBreadcrumb items={breadcrumbItems} />
      </div>
      <div className="container min-h-screen">
        {currentStep != 1 && <HeaderProjectDescription idParams={idParams} />}
        <div className="flex flex-wrap w-full items-start">
          <div className="basis-full lg:basis-1/4 pr-4 lg:pt-4">
            {currentId && <Treeview handleChangeStep={handleChangeStep} currentStep={currentStep}  />}
          </div>
          {!loadData && (
            <div
              className={`basis-full p-4 lg:basis-3/4`}
            >
              {/* {currentStep >= 2 && (
                <ProgressBar
                  onCurrentChange={handleChangeStep}
                />
              )} */}
              <StepRenderer
                currentStep={currentStep}
                onCurrentChange={handleChangeStep}
              />
            </div>
          )}
          {loadData && <LoadingSpinner />}
        </div>
      </div>
    </TypeProjectProvider>
  );
};

const HeaderProjectDescription: FC<{ idParams: number }> = ({ idParams }) => {
  const [searchParams] = useSearchParams();
  const slugTypeProject = searchParams.get("type") || "";
  const { programData } = useProgram();
 
  const project = programData[idParams]?.projects?.filter(item => item.type == slugTypeProject) || [];
  const nameProject = project.length > 0 ? project[0].name : ''
  const descriptionProject = project.length > 0 ? project[0].description : ''
  return (
    <div>
      <HeaderForm
        project={
          nameProject
        }
        description={descriptionProject}
        picture="/image-en-attente.jpg"
      />
    </div>
  );
};

export default Program;
