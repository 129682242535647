import LoginForm from "@/auth/components/LoginForm";
import RegistrationForm from "@/auth/components/RegistrationForm";
import { useAuth } from "@/auth/hooks/useAuth";
import { useNavigate } from "react-router-dom";

const Auth = () => {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuth();
  //Si l'utilisation est déjà connecté, on le redirige vers la page program

  const code = localStorage.getItem("code")

  if(code !== import.meta.env.VITE_API_CODE) {
    navigate(`/`);
  }

  if (isAuthenticated && user?.isVerified) {
    navigate(`/programs`);
  }
  //Todo: Rédirection de l'utilisateur vers la page code s'il n'est pas vérifié et on lui envoie un code mail
  return (
    <div className="container min-h-screen">
      <div className="flex flex-wrap w-3/4 mx-auto mb-5 mt-8">
        <div className="basis-full md:basis-1/2 md:pr-6 mb-5 md:mb-0">
          <LoginForm />
        </div>
        <div className="basis-full md:basis-1/2 md:pl-6">
          <RegistrationForm />
        </div>
      </div>
    </div>
  );
};

export default Auth;
