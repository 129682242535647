import { buttonVariants } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { MoveRight } from "lucide-react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Home = () => {
  const [code, setCode] = useState("");
  const navigate = useNavigate();

  return (
    <div className="container min-h-screen">
      <div className="flex flex-wrap lg:items-center ">
        <div className="basis-full md:basis-1/2 lg:basis-2/5 md:pr-8 mb-6 md:mb-0">
          <img
            src="/verdeo-home.jpg"
            alt="verdeo home"
            className="sticky lg:relative lg:top-0 top-10"
          />
        </div>
        <div className="basis-full md:basis-1/2 lg:basis-3/5 md:pl-8">
          <h1 className="text-2xl font-bold mb-5">
            Soumettez <span className="text-primary">un projet </span> régénératif pour obtenir un soutien financier
          </h1>
          <p>
            Vous avez un projet à fort impact régénératif, déjà bien défini et chiffré, mais vous manquez 
            de soutien financier pour le concrétiser ?
          </p>
          <p>
            Verdeo est une plateforme dédiée aux porteurs de projets régénératifs, offrant non seulement 
            un espace pour décrire et présenter vos initiatives, mais aussi des outils gratuits pour assurer 
            la traçabilité et le suivi de vos projets dans le temps.
          </p>
          <p>
            Cet espace est ouvert à tous les acteurs engagés dans la régénération des écosystèmes, quel 
            que soit le secteur ou le type de projet :
          </p>
          <ul className="list-disc ml-8 text-card-foreground">
            <li className="mb-2">
            <strong>Associations</strong> cherchant à dédier moins de temps à la recherche de fonds et plus à leurs
            actions concrètes.
            </li>
            <li className="mb-2">
            <strong>Agriculteurs</strong> souhaitant adopter des pratiques régénératives sans produits chimiques 
            et nécessitant un financement pour faciliter leur transition.
            </li>
            <li className="mb-2">
            <strong>Entrepreneurs, particuliers ou collectivités</strong> avec des projets écologiques innovants 
            qui ont besoin d'un coup de pouce financier ponctuel.
            </li>
            <p>En vous inscrivant sur Verdeo, vous bénéficiez également d'outils gratuits pour assurer la 
              traçabilité de vos projets, du dépôt jusqu'à la mise en œuvre. Nous nous occupons de trouver 
              les financements adaptés, vous vous concentrez sur l'impact régénératif.</p>
          </ul>
        <div className="text-center mt-8 flex">
          <Input className="w-[200px] mr-4" name="code" value={code} onChange={(e) => setCode(e.target.value)} placeholder="Code d'accès"/>
          <div
            onClick={(e) => {
              e.preventDefault();
              if(code === import.meta.env.VITE_API_CODE) {
                localStorage.setItem("code", code);
                navigate("/login");
              }

            }}
            className={buttonVariants({ variant: "default", className: `group ${code !== import.meta.env.VITE_API_CODE && 'disabled opacity-60 cursor-not-allowed'}` })}
          >
            Suivant <MoveRight className="ml-2 group-hover:ml-4 transition-all" />
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
